import request from '@/http.js'

/*****************************门店管理*****************************/
/**
 * @description 门店管理 -- 列表
 * @param {Object} param data {Object} 传值参数
 */
export function houseListApi (param) {
    return request.post(
        '/admin.warehouse.warehouse/getList',
        param
    )
}

/**
 * @description 门店管理 -- 删除
 * @param {Object} param data {Object} 传值参数
 */
 export function houseDeleteApi (param) {
    return request.post(
        '/admin.warehouse.warehouse/delete',
        param
    )
}

/**
 * @description 门店管理 -- 保存
 * @param {Object} param data {Object} 传值参数
 */
 export function houseSaveApi (param) {
    return request.post(
        '/admin.warehouse.warehouse/edit',
        param
    )
}

/**
 * @description 门店管理 -- 详情
 * @param {Object} param data {Object} 传值参数
 */
 export function houseReadApi (param) {
    return request.post(
        '/admin.warehouse.warehouse/read',
        param
    )
}

/**
 * @description 门店管理 -- 获取负责人
 * @param {Object} param data {Object} 传值参数
 */
 export function houseGetAdminApi (param) {
    return request.post(
        '/admin.warehouse.warehouse/getUserList',
        param
    )
}

/**
 * @description 门店管理 -- 设置状态
 * @param {Object} param data {Object} 传值参数
 */
 export function houseSwitchApi (param) {
    return request.post(
        '/admin.warehouse.warehouse/setStatus',
        param
    )
}

/**
 * @description 进入门店 -- 设置状态
 * @param {Object} param data {Object} 传值参数
 */
 export function enterHouseApi (param) {
    return request.post(
        '/admin.warehouse.warehouse/setWarehouse',
        param
    )
}

/**
 * @description 返回门店 -- 设置状态
 * @param {Object} param data {Object} 传值参数
 */
 export function backPlatformApi (param) {
    return request.post(
        '/admin.warehouse.warehouse/getBack',
        param
    )
}



/*****************************门店平台管理*****************************/

/**
 * @description 门店商品入库 -- 列表
 * @param {Object} param data {Object} 传值参数
 */
 export function housePlatformListApi (param) {
    return request.post(
        '/admin.warehouse.productInput/getList',
        param
    )
}

/**
 * @description 门店商品入库 -- 新建入库
 * @param {Object} param data {Object} 传值参数
 */
 export function housePlatformAddApi (param) {
    return request.post(
        '/admin.warehouse.productInput/add',
        param
    )
}

/**
 * @description 门店商品入库 -- 保存
 * @param {Object} param data {Object} 传值参数
 */
 export function housePlatformSaveApi (param) {
    return request.post(
        '/admin.warehouse.productInput/edit',
        param
    )
}

/**
 * @description 门店商品入库 -- 删除
 * @param {Object} param data {Object} 传值参数
 */
 export function housePlatformDeleteApi (param) {
    return request.post(
        '/admin.warehouse.productInput/delete',
        param
    )
}

/*****************************门店平台出库*****************************/
/**
 * @description 门店线下出库
 * @param {Object} param data {Object} 传值参数
 */
 export function outHouseApi (param) {
    return request.post(
        '/admin.warehouse.productOutput/getUserInfo',
        param
    )
}
/**
 * @description 门店线下出库 -- 扫码获取商品
 * @param {Object} param data {Object} 传值参数
 */
 export function outHouseShopListApi (param) {
    return request.post(
        '/admin.warehouse.productOutput/getProductByCode',
        param
    )
}

/**
 * @description 门店线下出库 -- 保存
 * @param {Object} param data {Object} 传值参数
 */
 export function outHouseSaveApi (param) {
    return request.post(
        '/admin.warehouse.productOutput/edit',
        param
    )
}

/*****************************门店平台商品订单*****************************/

/**
 * @description 门店商品订单
 * @param {Object} param data {Object} 传值参数
 */
 export function OrderListApi (param) {
    return request.post(
        '/admin.warehouse.productOutput/getList',
        param
    )
}

/**
 * @description 门店商品订单 -- 接单
 * @param {Object} param data {Object} 传值参数
 */
 export function acceptOrderApi (param) {
    return request.post(
        '/admin.warehouse.productOutput/taking',
        param
    )
}

/*****************************门店账号管理*****************************/
/**
 * @description 门店账号管理 -- 列表
 * @param {Object} param data {Object} 传值参数
 */
 export function houseAdminListApi (param) {
    return request.post(
        '/admin.warehouse.warehouse/getMemberList',
        param
    )
}

/*****************************门店导出*****************************/
/**
 * @description 门店导出
 * @param {Object} param data {Object} 传值参数
 */
 export function houseExportExcelApi (param) {
    return request.post(
        '/exportExcel/order',
        param
    )
}


/*****************************门店库存预警*****************************/
/**
 * @description 门店导出
 * @param {Object} param data {Object} 传值参数
 */
 export function stockWarningApi (param) {
    return request.post(
        '/admin.warehouse.warehouse/stockWarning',
        param
    )
}

/**
 * @description 门店订单完成
 * @param {Object} param data {Object} 传值参数
 */
 export function orderOverApi (param) {
    return request.post(
        '/admin.warehouse.productOutput/customerPickup',
        param
    )
}

/**
 * @description 门店列表
 * @param {Object} param data {Object} 传值参数
 */
 export function getHouseNameApi (param) {
    return request.post(
        '/admin.warehouse.warehouse/getNameList',
        param
    )
}
